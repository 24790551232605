<template>
  <div>
    <client-only>
      <poll-component
        v-if="engagement && engagement.id"
        :poll-data="engagement"
      />
    </client-only>
  </div>
</template>

<script>
import PollComponent from '@/components/EngagementToolkit/PollComponent.vue';
import { GET_ENGAGEMENT } from '@/graphql/queries/engagement-toolkit-queries';

export default {
  name: 'PollQuery',
  components: {
    PollComponent,
  },
  props: {
    pollId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      engagement: {},
    };
  },
  apollo: {
    engagement: {
      query: GET_ENGAGEMENT,
      variables() {
        return {
          id: this.pollId ? this.pollId : null,
          type: 'poll',
        };
      },
    },
  },
};
</script>
